@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Open+Sans:wght@400;500&display=swap');

// 簡易的なリセット
* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans JP', sans-serif;
  box-sizing: border-box;
}

body {
  background-color: #F5F5F5;
}